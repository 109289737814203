import { Dispatch } from 'redux';

import { getQuizDetails } from '../actions/quiz';

interface ProductSettings {
  backgroundCategoryIds: number[];
}

type QuizMap = Record<string, ProductSettings>;

interface Args {
  dispatch: Dispatch;
  quizMap: QuizMap;
  customerId: number;
}

export const getQuizBackgroundCategoryIds = async ({
  dispatch,
  quizMap,
  customerId,
}: Args) => {
  const result = await dispatch(getQuizDetails(customerId) as any);
  const payload = result?.payload;
  return payload.reduce((arr, item) => {
    return item.name in quizMap && item.value === 'YES'
      ? [
          ...arr,
          ...quizMap[item.name].backgroundCategoryIds.map(
            backgroundCategoryId => {
              return { backgroundCategoryId };
            }
          ),
        ]
      : arr;
  }, []);
};
