import type { Component } from '@builder.io/sdk';

export const BuilderPrivacyFormWrapperConfig: Component = {
  name: '_BuilderPrivacyFormWrapper_',
  defaultStyles: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inputs: [],
};
