export const fplSortOption = {
  value: 'fplasc',
  sort: 'fplasc',
  defaultMessage: 'Recommended',
  isDefault: true,
};

export const getNewestSortOption = filterLabel => ({
  value: 'newest',
  // * Keyword sort automatically selects associated required fields in Tier1
  sort: 'newest',
  // * Array sort can be used to specify fields and directions explicitly
  // `newest` in Tier1 translates to:
  // [
  //   { field: 'date_expected', direction: 'desc' },
  //   { field: 'sales_count_10_days', direction: 'desc' },
  //   { field: 'review_count', direction: 'desc' },
  // ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Newest',
});

export const getBestSellersSortOption = filterLabel => ({
  value: 'bestsellers',
  sort: 'bestsellers',
  defaultMessage: filterLabel?.length ? filterLabel : 'Best Sellers',
});

export const getTopReviewedSortOption = filterLabel => ({
  value: 'topreviewed',
  sort: 'topreviewed',
  defaultMessage: filterLabel?.length ? filterLabel : 'Highest Rated',
});

export const getPriceLowToHighSortOption = (retailPriceSort, filterLabel) => ({
  value: 'pricel2h',
  sort: [
    {
      field: retailPriceSort ? 'retail_unit_price' : 'default_unit_price',
      direction: 'asc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Price: Low to High',
});

export const getPriceHighToLowSortOption = (retailPriceSort, filterLabel) => ({
  value: 'priceh2l',
  sort: [
    {
      field: retailPriceSort ? 'retail_unit_price' : 'default_unit_price',
      direction: 'desc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Price: High to Low',
});

export const getDefaultSortOptions = retailPriceSort => [
  { ...getNewestSortOption(), isDefault: true },
  getBestSellersSortOption(),
  getTopReviewedSortOption(),
  getPriceLowToHighSortOption(retailPriceSort),
  getPriceHighToLowSortOption(retailPriceSort),
  getSortOptionBras(),
  getSortOptionPanties(),
];

export const getSortOptionBras = filterLabel => ({
  value: 'bras',
  sort: [
    {
      field: 'sort_list_0003',
      direction: 'asc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Default',
});

export const getSortOptionPanties = filterLabel => ({
  value: 'panties',
  sort: [
    {
      field: 'sort_list_0004',
      direction: 'asc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Default',
});

export const parseSortOptionsFromSortList = (sortList, retailPriceSort) => {
  const sortOptions = sortList
    .map(sortListItem => {
      if (sortListItem?.sortOption) {
        switch (sortListItem?.sortOption) {
          case 'newest':
            return getNewestSortOption(sortListItem.filterLabel);
          case 'bestsellers':
            return getBestSellersSortOption(sortListItem.filterLabel);
          case 'topReviewed':
            return getTopReviewedSortOption(sortListItem.filterLabel);
          case 'priceLowToHigh':
            return getPriceLowToHighSortOption(
              retailPriceSort,
              sortListItem.filterLabel
            );
          case 'priceHighToLow':
            return getPriceHighToLowSortOption(
              retailPriceSort,
              sortListItem.filterLabel
            );
          case 'bras':
            return getSortOptionBras(sortListItem.filterLabel);
          case 'panties':
            return getSortOptionPanties(sortListItem.filterLabel);
          case 'fplSort':
            return {
              ...fplSortOption,
              isDefault: false,
              defaultMessage: sortListItem.filterLabel?.length
                ? sortListItem.filterLabel
                : 'Recommended',
            };
        }
      }

      return {};
    })
    .filter(value => value);

  // the first option is the default
  if (sortOptions?.length) {
    sortOptions[0].isDefault = true;
  }
  return sortOptions;
};
