import { createSelector } from '@techstyle/redux-core';

const combinedSizes = {
  XS: ['XS', 'XS/S'],
  S: ['S', 'XS/S'],
  M: ['M', 'M/L'],
  L: ['L', 'M/L'],
  XL: ['XL', 'XL/1X'],
  '1X': ['1X', 'XL/1X'],
  '2X': ['2X', '2X/3X'],
  '3X': ['3X', '2X/3X'],
};

export const plusSizesUS = new Set(['1X', '2X', '3X', 'XL/1X', '2X/3X']);
export const plusSizesEU = new Set(['2XL', '3XL', '4XL', 'XL/2XL', '3XL/4XL']);

export const getPlusSizes = createSelector([state => state.domain.tld], tld => {
  switch (tld) {
    case '.co.uk':
    case '.de':
    case '.es':
    case '.eu':
    case '.fr':
      return plusSizesEU;

    // * '.com' will be captured here
    default:
      return plusSizesUS;
  }
});

// * This links the keys expected by the (aggregations) API against the brand
// * size keys returned by `useCustomer()`
export const aggregationKeyMap = {
  size_bra: 'bra-size',
  size_bralette: 'bralette-size',
  size_undie: 'bottom-size',
  size_lingerie: 'lingerie-sleep-size',
};

/**
 * Checks if any of the users profile sizes are in the brand's set of plus sizes
 */
export const getIsPlusProfile = createSelector(
  [getPlusSizes, (state, profileSizes) => profileSizes],
  (plusSizes, profileSizes) => {
    if (!profileSizes) {
      return false;
    }
    return Object.values(profileSizes).some(profileSize =>
      plusSizes.has(profileSize)
    );
  }
);

/**
 * Applies the user's profile size to the given `initialFilters`. If no filters
 * are modified, then the same `initialFilters` object is returned.
 */
export function applyProfileSizeFilters({ profileSizes, initialFilters }) {
  return initialFilters.aggregations.reduce((filters, aggregation) => {
    const { field } = aggregation;
    const hasExistingValue =
      filters.aggregationFilter[field] != null &&
      filters.aggregationFilter[field].length > 0;

    // If there are already filters applied to this field, we shouldn't
    // touch it.
    if (hasExistingValue) {
      if (field === 'size_lingerie') {
        filters = {
          ...filters,
          aggregationFilter: {
            ...filters.aggregationFilter,
            [field]: Array.from(
              new Set([...filters.aggregationFilter[field], 'OSFM'])
            ),
          },
        };
      }
      return filters;
    }

    const profileValue = profileSizes[field];

    if (profileValue != null) {
      filters = {
        ...filters,
        aggregationFilter: {
          ...filters.aggregationFilter,
          [field]:
            field === 'size_lingerie'
              ? Array.from(
                  new Set([
                    ...(combinedSizes[profileValue] || [profileValue]),
                    'OSFM',
                  ])
                )
              : combinedSizes[profileValue] || [profileValue],
        },
      };
    }

    return filters;
  }, initialFilters);
}

export const getAggregationValues = profile => {
  return Object.entries(aggregationKeyMap).reduce(
    (aggregationValues, [aggregationField, profileKey]) => {
      const profileValue = profile[profileKey];
      // Assume a value of `0` is valid.
      if (profileValue != null && profileValue !== '') {
        const filterValue = profileValue;
        if (filterValue != null && filterValue.indexOf('not_interested') < 0) {
          aggregationValues[aggregationField] = filterValue;
        }
      }
      return aggregationValues;
    },
    {}
  );
};

/**
 * Selector to retrieve the user's profile sizes as an object mapping
 * aggregation field to filter value.
 */
export const getProfileSize = createSelector(
  [state => state.customer.profile],
  profile => {
    if (!profile) {
      return null;
    }

    return getAggregationValues(profile);
  }
);

export function hasQueryMatchingFilters(query, aggregationFilter) {
  if (!query || !aggregationFilter) {
    return false;
  }
  const filterKeys = Object.keys(aggregationFilter);

  return [...filterKeys, 'sort'].some(key => key in query);
}
