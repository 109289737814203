import type { Component } from '@builder.io/sdk';

export const BuilderTrackingWrapperConfig: Component = {
  name: '_TrackingWrapper_',
  noWrap: true,
  canHaveChildren: true,
  static: true,
  defaultStyles: {
    marginTop: '0',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  inputs: [
    {
      name: 'content',
      type: 'uiBlocks',
      defaultValue: [],
    },
    {
      name: 'triggerClickEvent',
      helperText: 'If triggerClickEvent is enabled, target Url is required.',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'targetUrl',
      helperText: 'If triggerClickEvent is enabled, target Url is required.',
      type: 'string',
      required: true,
      showIf: (options: any) => options.get('triggerClickEvent') === true,
    },
    {
      name: 'triggerViewEvent',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'triggerViewEventOnce',
      helperText:
        'If enabled, triggers the view event only once in the same session.',
      type: 'boolean',
      defaultValue: true,
      showIf: (options: any) => options.get('triggerViewEvent') === true,
    },
    {
      name: 'name',
      type: 'string',
      required: true,
    },
    {
      name: 'description',
      helperText: 'Corresponds to the "creative" field.',
      type: 'string',
      required: true,
    },
    {
      name: 'promotionId',
      helperText: 'Corresponds to the "image_id" field.',
      type: 'string',
      required: true,
    },

    {
      name: 'customVars',
      friendlyName: 'Custom Vars (JSON)',
      type: 'json',
      defaultValue: {},
      localized: false,
      helperText: "'{}' will be treated as an empty field.",
    },
  ],
};
