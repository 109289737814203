import { loadMembership } from '@techstyle/react-accounts';
import { getDirectMarketingDisposition } from '@techstyle/react-marketing';
import { getSession } from '@techstyle/redux-core';

import { getBuilderJson } from './getBuilderJson';
import { getExperienceId } from './getExperienceId';
import { isBuilderPreview } from './isBuilderPreview';
import { loadAsyncBuilderComponents } from './loadAsyncBuilderComponents';
import { getBuilderMembershipStatus } from './selectors';
import { ssrPreprocessBuilderJson } from './ssrPreprocessBuilderJson';

export const getBuilderJsonSSR = async ({
  modelName,
  urlPath,
  ctx,
  query = {},
}) => {
  const isPreview = isBuilderPreview(ctx.req);
  if (isPreview && modelName !== 'landing-page') {
    // when previewing, we return a loading indicator
    // to be shown until the Builder library fetches the preview content
    return {
      builderJson: {
        data: {
          blocks: [
            {
              '@type': '@builder.io/sdk:Element',
              id: 'text-block',
              component: {
                name: 'Text',
                options: { text: 'Loading Preview...' },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '24px',
                  fontSize: '32px',
                  textAlign: 'center',
                },
              },
            },
          ],
        },
        id: 'preview-text-block',
      },
      builderStateData: {},
    };
  }

  const state = ctx.store.getState();
  const { locale } = state.intl;
  const { tld } = state.domain;
  const { dmGatewayCode, isLoggedIn } = getSession(state);
  const disposition = getDirectMarketingDisposition(ctx.store.getState());

  await ctx.store.dispatch(loadMembership());

  const { membershipStatus } = getBuilderMembershipStatus(ctx.store.getState());

  const experienceId = await getExperienceId({
    dispatch: ctx.store.dispatch,
    disposition,
    dmGatewayCode,
    isLoggedIn,
    query: ctx.query,
  });

  const pageOptions = {
    experienceId,
    isLoggedIn,
    isPreview,
    locale,
    membershipStatus,
    tld,
  };

  const builderContentParams = {
    modelName,
    urlPath,
    query,
    pageOptions,
  };

  const { builderJson } = await getBuilderJson(builderContentParams, ctx);

  let builderStateData;

  if (!isPreview) {
    /**
     * Loads asynchronous builder components
     * @components (BuilderCollection, BuilderGridList)
     */
    builderStateData = await loadAsyncBuilderComponents({
      ctx,
      builderJson,
    });

    /**
     * Preloads assets/containers
     */
    await ssrPreprocessBuilderJson(ctx, builderJson);
  }

  return { builderJson, builderStateData };
};
