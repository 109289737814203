import type { Component } from '@builder.io/sdk';

export const supportedAggregations = [
  'category_label',
  'collection',
  'color',
  'default_product_category_id',
  'fabric',
  'gender',
  'product_category_id_list',
  'size',
  'size_bra',
] as const;

export const BuilderGridFilterBarConfig: Component = {
  name: '_GridFilterBar_',
  friendlyName: 'Grid Filter Bar',
  inputs: [
    {
      name: 'defaultTitle',
      friendlyName: 'Default Title',
      helperText: 'The default title for the grid when no slides are selected',
      type: 'string',
      localized: true,
      defaultValue: 'All Products',
    },
    {
      name: 'shortTitle',
      friendlyName: 'Shortened Title',
      helperText: 'The title that is displayed above the selected filters.',
      type: 'string',
      defaultValue: 'Products',
      localized: true,
    },
    {
      name: 'filterItems',
      friendlyName: 'Filter Items',
      helperText: 'The filter items that will be displayed in the filter bar',
      type: 'list',
      defaultValue: [
        {
          label: 'Demi Bras',
          filterOptions: [
            {
              filter: {
                filterType: 'product_category_id_list',
                filterValue: '1580',
              },
            },
          ],
        },
      ],
      subFields: [
        {
          name: 'label',
          friendlyName: 'Label',
          helperText: 'The label that is displayed on the slide',
          type: 'string',
          required: true,
          localized: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        },
        {
          name: 'trackingKey',
          friendlyName: 'Tracking Key',
          helperText:
            'The tracking key added to the slide that can be used to track interactions with the slide using analytics tools. This value must be unique across all slides in a given Filter Bar. This gets added as `data-filter-bar-tracking-key` to the slide. When a value is not provided, the label is used.',
          type: 'string',
          localized: false,
        },
        {
          name: 'filterOptions',
          friendlyName: 'Filter Options',
          helperText:
            'The filter options that will be applied when this slide is selected.',
          type: 'list',
          required: true,
          subFields: [
            {
              name: 'filter',
              type: 'object',
              required: true,
              defaultValue: {
                filterType: 'product_category_id_list',
                filterValue: '1580',
              },
              subFields: [
                {
                  name: 'filterType',
                  type: 'string',
                  friendlyName: 'Aggregation Filter Field',
                  helperText:
                    'Note: Options other than "product_category_id_list" _may_ not work as expected at this time due to usage in the Filter Side Bar, please use with caution.',
                  defaultValue: 'product_category_id_list',
                  enum: [...supportedAggregations],
                  required: true,
                },
                {
                  name: 'filterValue',
                  friendlyName: 'Filter Value',
                  helperText:
                    'The value(s) that will be used to filter by for this filter type. Multiple values can be provided, separated by commas.',
                  type: 'string',
                  defaultValue: '1580',
                  required: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

/**
 * The commented out code below is the Builder Validation Hook that ensures that all filter options contain a filter value.
 * This is currently necessary because the `required` flag on the `filterValue` field does not currently work as expected. There
 * is an open issue in Builder related to this: https://forum.builder.io/t/is-it-possible-to-show-validation-error-when-list-subfield-marked-as-required-is-empty/5176
 */

// /**
//  * NOTE: This validation hook currently only supports the case where an empty filter value
//  * is provided.
//  */
// async function run() {
//   let hasError = false;

//   contentModel.data.get('blocks').forEach(data => {
//     if (data?.component?.name === '_GridFilterBar_') {
//       const filterItems = data.component.options.toJSON()?.filterItems;
//       if (filterItems) {
//         filterItems.forEach(singleFilterItem => {
//           singleFilterItem?.filterOptions.some(singleFilter => {
//             if (!singleFilter?.filter.filterValue?.trim()) {
//               hasError = true;
//               return false;
//             }
//           });
//         });
//       }
//     }
//   });

//   if (hasError) {
//     return {
//       level: 'error',
//       message:
//         'Please ensure that all Filter Bar filters contain a "Filter Value"',
//     };
//   }
// }

// return run();
// `;
