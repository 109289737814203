import type { Component } from '@builder.io/sdk';

export const BuilderQuizCompleteConfig: Component = {
  name: '_QuizComplete_',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: '36px 0 36px 0',
  },
  inputs: [
    {
      name: 'redirectUrl',
      friendlyName: 'Redirect URL',
      type: 'url',
      defaultValue: '/',
    },
    {
      name: 'redirectUrlVip',
      friendlyName: 'Redirect URL VIP',
      helperText:
        'Only applied to VIP users. If left empty, "Redirect URL" will be used instead.',
      type: 'url',
      defaultValue: '/',
    },
    {
      name: 'showPasswordField',
      friendlyName: 'Show Password Field',
      helperText: 'Shows/hides password field for passwordless flow',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'headerTitle',
      friendlyName: 'Header Title',
      helperText: 'Form title message',
      type: 'html',
      defaultValue: '',
    },
    {
      name: 'headerSubtitle',
      friendlyName: 'Header Subtitle',
      helperText: 'Form Subtitle message',
      type: 'html',
      defaultValue: '',
    },
    {
      name: 'subscribeToEmailsLabel',
      friendlyName: "Subscribe to emails' label",
      helperText: 'Email opt-in message (only non-US sites)',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'privacyNotice',
      friendlyName: 'Privacy Notice',
      helperText: 'Displayed above submit button (only non-US sites)',
      type: 'html',
      defaultValue: '',
    },
    {
      name: 'haveAnAccountMessage',
      friendlyName: 'Have an account message',
      helperText: 'Message displayed right under submit button',
      type: 'html',
      defaultValue: 'Already have an account?',
    },
    {
      name: 'terms',
      friendlyName: 'Terms message',
      helperText: 'Privacy and terms message',
      type: 'html',
      defaultValue: '',
    },
  ],
};
