import type { Component } from '@builder.io/sdk';

export const BuilderVIPMembershipConfig: Component = {
  name: '_VIPMembership_',
  friendlyName: 'VIPMembership',
  inputs: [
    {
      name: 'borderRadius',
      friendlyName: 'Border radius',
      type: 'string',
      defaultValue: '0px',
    },
    {
      name: 'navigation',
      friendlyName: 'Navigation',
      helperText: 'Configuration to make the whole component clickable',
      type: 'object',
      subFields: [
        {
          name: 'url',
          type: 'string',
          localized: false,
        },
        {
          name: 'openInNewTab',
          friendlyName: 'Open in new tab',
          type: 'boolean',
          defaultValue: false,
          localized: false,
        },
      ],
    },
    {
      name: 'header',
      friendlyName: 'Header',
      helperText: 'Header text',
      type: 'string',
      defaultValue: 'How Xtra VIP Membership Works',
      localized: true,
    },
    {
      name: 'subHeader',
      friendlyName: 'Subheader',
      helperText: 'Subheader text',
      type: 'string',
      defaultValue:
        'As an Xtra VIP, you get access to Xclusive VIP pricing, and up to 25% off full-price everyday!',
      localized: true,
    },
    {
      name: 'slides',
      friendlyName: 'Slides',
      helperText: 'Carousel slides',
      type: 'list',
      defaultValue: [
        {
          image: {
            src: 'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2F8411638728da4a759a38f0f043a09a15',
            alt: 'Icon 1',
          },
          title: 'UNLOCK XCLUSIVE PERKS WITH MONTHLY BILLING',
          description:
            "On the 6th of each month, if you haven't chosen to 'Skip the Month,' your payment method will be                                     charged $59.95, which unlocks Xclusive Perks including 1 Member Credit that expires after 12 months.",
        },
        {
          image: {
            src: 'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2Fa56da7c8c8964621b4b77e30c296d38f',
            alt: 'Icon 3',
          },
          title: 'SKIP AS MANY TIMES AS YOU LIKE...',
          description:
            'The choice is always yours. Click “Skip This Month” between the 1st and 5th of each month, and you won’t be charged. Skip as many times as you like—there is no limit.',
        },
        {
          image: {
            src: 'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2Ffff67683118a4696a3f05bd2e6b6765c',
            alt: 'Icon 3',
          },
          title: 'CANCEL ANYTIME',
          description:
            'Cancel anytime by calling our Customer Service Representatives at (855) SAVAGEX (open 24/7) or manage online.',
        },
      ],
      subFields: [
        {
          name: 'image',
          type: 'object',
          subFields: [
            {
              name: 'src',
              friendlyName: 'Image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2Ffff67683118a4696a3f05bd2e6b6765c',
              required: true,
            },
            {
              name: 'alt',
              friendlyName: 'Alt text',
              type: 'string',
              defaultValue: 'icon',
              required: true,
            },
          ],
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'description',
          type: 'string',
        },
      ],
    },
    {
      name: 'cta',
      friendlyName: 'CTA',
      type: 'object',
      defaultValue: {
        label: 'LEARN MORE',
        link: '/',
      },
      subFields: [
        {
          name: 'label',
          type: 'string',
          friendlyName: 'Label',
          required: true,
        },
        {
          name: 'link',
          type: 'string',
          friendlyName: 'Link',
          required: true,
        },
      ],
    },
  ],
};
