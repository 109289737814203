import type { Component } from '@builder.io/sdk';

export const BuilderQuizListSelectorConfig: Component = {
  name: '_QuizListSelector_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
  },
  inputs: [
    {
      name: 'id',
      type: 'string',
      required: true,
      defaultValue: 'optionId',
    },
    {
      name: 'options',
      type: 'list',
      defaultValue: [
        {
          id: 'OptionId',
          label: 'Option Title',
        },
      ],
      subFields: [
        {
          name: 'id',
          type: 'number',
          required: true,
        },
        {
          name: 'label',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'NEXT',
      localized: true,
    },
    {
      name: 'shouldAnimate',
      type: 'boolean',
      defaultValue: true,
    },
  ],
};
