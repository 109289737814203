import type { Component } from '@builder.io/sdk';

export const BuilderQuizNextButtonConfig: Component = {
  name: '_QuizNextButton_',
  friendlyName: 'NextButton',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  inputs: [
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'NEXT',
      localized: true,
    },
    {
      name: 'shouldAnimate',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'doNotAnchorBottom',
      friendlyName: 'Do not anchor to the bottom of the step.',
      helperText:
        'By default, the next button will be positioned on the bottom of each step. Enabling this allows the next button to be positioned freely.',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
    },
  ],
};
