import { loadProfile } from '@techstyle/react-accounts';

import { getProductGridItem } from './getProductGridItem';
import { getQuizBackgroundCategoryIds } from './getQuizBackgroundCategoryIds';

const mapBuilderAggregations = (array, key, propName = 'value', options = {}) =>
  array
    ?.filter(value => !!value[key])
    ?.map((value, i) => ({
      id: i,
      [propName]: value[key],
      ...options,
    })) ?? [];

export const getBuilderCollectionContent = async ({
  options,
  dispatch,
  routeInfo,
  store,
  displayRetailPrice,
  query,
  ctx,
  quizMap,
}) => {
  const {
    isCarousel,
    carouselOptions,
    algoOptions,
    fontVariant,
    gridCTA,
    gridOptions,
    gridSortFeature,
    gridStyleGuide,
    gridUrlLink,
    imageType,
    includeOutOfStock,
    productTypeIds,
    sort,
    sortList,
    tagIds,
    excludePreorderProducts,
    developerSettings,
    productSettings,
    productApi,
  } = options;

  const {
    fpls,
    backgroundFplIds,
    backgroundFplCodes,
    filters,
    productSource,
    label,
    header,
    maxItemCount,
    excludeFpls,
    excludeMasterProductIds,
    excludeTagIds,
  } = productSettings || {};

  const {
    blocks,
    gridAssetContainer,
    gridAssetContainerMobile,
    sideNav,
    elementsToHide,
    inlineSizeCategory,
    inlineSizeAsset,
    showDescriptiveText,
    showLaydown,
    shouldTrackProductPriceViewed,
  } = gridOptions || {};

  const hideSideBar = !sideNav?.showSideNav;

  let { customerId } = store.getState().membership;

  if (!customerId) {
    await store.dispatch(loadProfile());
    customerId = store.getState().membership.customerId;
  }

  let backgroundCategoryIds = productSettings?.backgroundCategoryIds ?? [];

  if (customerId && quizMap) {
    const quizBackgroundCategoryIds = await getQuizBackgroundCategoryIds({
      dispatch: store.dispatch,
      customerId,
      quizMap,
    });

    backgroundCategoryIds = [
      ...backgroundCategoryIds,
      ...quizBackgroundCategoryIds,
    ];
  }

  const mappedBuilderBackgroundFplIds = mapBuilderAggregations(
    backgroundFplIds,
    'backgroundFplId'
  );
  const primaryFplId = mappedBuilderBackgroundFplIds?.[0]?.value;
  const gridNode = {
    __component: 'node.grid',
    aggsProductCategoryIds: [],
    algoOptions,
    availableQtyMin: null,
    boostedFplId: null,
    categoryIds: mapBuilderAggregations(
      backgroundCategoryIds,
      'backgroundCategoryId'
    ),
    initialProductsCachettl: developerSettings?.initialProductsCachettl,
    inlineSizeAsset,
    inlineSizeCategory,
    showDescriptiveText,
    showLaydown,
    shouldTrackProductPriceViewed,
    customFields: {
      aggregations: mapBuilderAggregations(
        isCarousel
          ? filters
          : [...(filters ?? []), { filter: 'product_category_id_list' }],
        'filter',
        'field',
        { size: 50 }
      ),
      aggregationFilter: productSettings?.aggregationFilter || {},
      color: productSettings?.color,
      hideSideBar,
      hideHeaderLabel: !sideNav?.showHeaderLabel,
      includeOutOfStock,
      imageType,
      showSort: !!sideNav?.showSort,
      shouldShowCategoryDropdown: !!sideNav?.showSideNav,
      showHeader: !!sideNav?.showHeader,
      showBreadcrumbs: !!sideNav?.showBreadcrumbs,
      excludePreorderProducts,
    },
    excludeCategoryIds: [],
    maxItemCount,
    sideNav,
    excludeFpls: mapBuilderAggregations(excludeFpls, 'excludeFpl'),
    excludeMasterProductIds: mapBuilderAggregations(
      excludeMasterProductIds,
      'excludeMasterProductId'
    ),
    excludeTagIds: mapBuilderAggregations(excludeTagIds, 'excludeTagId'),
    featuredGridBlocks: blocks,
    fontVariant,
    fpls: mapBuilderAggregations(fpls, 'fpl'),
    backgroundFplIds: mappedBuilderBackgroundFplIds,
    backgroundFplCodes: mapBuilderAggregations(
      backgroundFplCodes,
      'backgroundFplCode'
    ),
    primaryFplId,
    gridAssetContainer,
    gridAssetContainerMobile,
    gridCTA: {
      ...gridCTA,
      label: gridCTA?.label?.Default ? gridCTA.label : undefined,
      url: gridCTA?.url?.Default ? gridCTA.url : undefined,
    },
    gridSortFeature,
    gridStyleGuide: {
      shouldShowStyleGuide: !!gridStyleGuide,
      ...gridStyleGuide,
    },
    gridUrlLink,
    size: null,
    hideSortOption: sortList?.length <= 1,
    productSource,
    sort,
    sortList,
    includeOutOfStock: !!includeOutOfStock,
    label,
    loadMore: null,
    productTypeIds: mapBuilderAggregations(productTypeIds, 'productTypeId'),
    tagIds: mapBuilderAggregations(tagIds, 'tagId'),
    isCarousel,
    carouselOptions,
    elementsToHide,
    header,
    productApi,
  };

  let browserRouteInfo;
  if (process.browser) {
    const currentUrl = new URL(window.location);
    browserRouteInfo = {
      canonicalPath: currentUrl?.pathname,
    };
  }

  const collectionContent = await getProductGridItem({
    node: gridNode,
    productSource,
    dispatch,
    routeInfo: routeInfo || browserRouteInfo,
    store,
    displayRetailPrice,
    query: query || ctx.query,
    productApi,
    ctx,
    isRecommendedProducts: quizMap !== undefined,
  });

  return collectionContent;
};
