import * as Sentry from '@sentry/nextjs';

import { loadProfile } from '@techstyle/react-accounts';
import {
  loadProducts,
  parseProductListingQueryParamsCustomFilters,
} from '@techstyle/react-products';

import {
  applyProfileSizeFilters,
  getProfileSize,
  hasQueryMatchingFilters,
} from '../../src/utils/productSizes';
import {
  addAllGenderSizesToFilters,
  addMenSizeUndieToFilters,
  convertUndiesToBoxerSizes,
} from '../../src/utils/sizes';

import { getProductGridItemCategoryNavSettings } from './getProductGridItemCategoryNavSettings';
import { getProductGridItemFilters } from './getProductGridItemFilters';
import { loadNorthStarProducts } from './northStarProductsV2';
import {
  addNewDefaultSortOption,
  attachRewriteFeatureSortToSortOptions,
  pageSize,
} from './products';
import { getDefaultProductFields, getDefaultProductFilters } from './selectors';
import {
  fplSortOption,
  getDefaultSortOptions,
  parseSortOptionsFromSortList,
} from './setSortOptions';

export async function getProductGridItem({
  node,
  productSource,
  routeInfo,
  index,
  dispatch,
  store,
  displayRetailPrice,
  query,
  productApi,
  ctx,
  isRecommendedProducts,
}) {
  const shouldUseSharedFilters =
    node.customFields?.shouldUseSharedFilters || false;

  const label = node.label || '';
  const shouldShowColorsLink = node.customFields?.shouldShowColorsLink ?? false;

  const shouldShowGridReviews =
    node.customFields?.shouldShowGridReviews || false;
  const hideSideBar = node.customFields?.hideSideBar ?? false;
  const hideHeader = node.customFields?.hideHeader ?? false;
  const hideHeaderLabel = node.customFields?.hideHeaderLabel ?? true;
  const imageType = node.customFields?.imageType;
  const gridCTA = (node.gridCTA || node.hasGridCta) ?? {};
  const gridColumns = (node.customFields.gridColumns || node.gridColumns) ?? {};
  const breakpoints =
    (node.customFields.breakpoints || node.breakpoints) ?? null;
  const gridAssetContainer =
    (node.customFields.gridAssetContainer || node.gridAssetContainer) ?? '';
  const gridAssetContainerMobile =
    (node.customFields.gridAssetContainerMobile ||
      node.gridAssetContainerMobile) ??
    '';
  const inlineSizeCategory =
    (node.customFields.inlineSizeCategory || node.inlineSizeCategory) ?? null;
  const inlineSizeAsset =
    (node.customFields.inlineSizeAsset || node.inlineSizeAsset) ?? false;
  const maxItemCount = node.maxItemCount || null;
  const header = node.header || null;
  const blocks = node.featuredGridBlocks || null;
  const showSort = node.customFields?.showSort ?? true;
  const showHeader = node.customFields?.showHeader ?? true;
  const showBreadcrumbs = node.customFields?.showBreadcrumbs ?? true;

  const { activeCategory, breadcrumbs, shouldShowCategoryDropdown } =
    getProductGridItemCategoryNavSettings({ node });

  const baseFilters = getProductGridItemFilters(node);
  // sort options

  const { feature } = routeInfo;
  let sortOptions = getDefaultSortOptions(displayRetailPrice);

  // check for fplid like in product-bmig, if so, add FPL default sorting
  const isFplSort =
    (Array.isArray(baseFilters?.backgroundFplIds) &&
      baseFilters.backgroundFplIds.length > 0) ||
    (Array.isArray(baseFilters?.fpls) && baseFilters.fpls.length > 0);

  if (isFplSort) {
    // * Prepend the FPL sort option as default if it's an FPL page.
    sortOptions = addNewDefaultSortOption(fplSortOption, sortOptions);
  }

  // If we have a rewrite sort we apply it
  const rewriteFeatureSort = attachRewriteFeatureSortToSortOptions(
    feature,
    sortOptions
  );

  let { initialSortOption } = rewriteFeatureSort;

  if (!initialSortOption) {
    initialSortOption = isFplSort
      ? fplSortOption
      : sortOptions.find(option => option.isDefault) || sortOptions[0];
  }

  // handler for the sort list builder input - overwrites default behavior
  if (node.sortList?.[0]?.sortOption) {
    sortOptions = parseSortOptionsFromSortList(
      node.sortList,
      displayRetailPrice
    );
    if (sortOptions?.length) {
      initialSortOption = sortOptions[0];
    }
  }

  let { filters: initialFilters, sortOption: urlSortOption } =
    parseProductListingQueryParamsCustomFilters(query, {
      baseFilters,
      filterSettings: baseFilters.aggregations,
      sortOptions,
    });

  // Set urlSortOption
  if (urlSortOption) {
    initialSortOption = urlSortOption;
  }

  await store.dispatch(loadProfile());

  const profileSizes = getProfileSize(store.getState());

  if (profileSizes) {
    /**
     * For recommended products, we include the sizes no matter whether it is present on `initialFilters.aggregations`
     * as performed by `applyProductProfileSizeFilters`, so we apply the sizes directly.
     */
    if (isRecommendedProducts) {
      const profileSizes = Object.fromEntries(
        Object.entries(getProfileSize(store.getState()) ?? {}).map(
          ([key, value]) => {
            let convertedValue =
              key === 'size_undie'
                ? convertUndiesToBoxerSizes([value])
                : [value];

            if (key === 'size_lingerie') {
              convertedValue = [...convertedValue, 'OSFM'];
            }

            return [key, value !== 'other' ? convertedValue : []];
          }
        )
      );

      initialFilters.aggregationFilter = {
        ...initialFilters.aggregationFilter,
        ...profileSizes,
      };
    } else {
      // if the user overrides the filter, don't apply the saved sizes.
      if (
        query?.my_size !== 'false' &&
        !hasQueryMatchingFilters(query, initialFilters?.aggregationFilter)
      ) {
        initialFilters = applyProfileSizeFilters({
          profileSizes,
          initialFilters,
        });
      }
    }
  }

  initialFilters = addMenSizeUndieToFilters(initialFilters);
  if (initialFilters.aggregationFilter.size_lingerie) {
    initialFilters = addAllGenderSizesToFilters(initialFilters);
  }

  const filters = getDefaultProductFilters(store.getState(), initialFilters, {
    autoExcludeClearanceFplIds: false,
  });

  const productRequestParams = createParams({
    initialSortOption,
    filters,
    productSource,
    maxItemCount,
  });

  let initialProducts;

  if (productApi === 'northstar') {
    initialProducts = await loadNorthStarProducts(
      ctx?.apolloClient,
      productRequestParams[0],
      {
        includeProductDimensions: true,
        deferDimensions: true,
        includeProductListing: true,
        includePricing: true,
        deferPricing: true,
      }
    );
  } else {
    initialProducts = await loadInitialProducts({
      params: productRequestParams,
      dispatch,
    });
  }

  const routeParams = {
    inlineSizeCategory,
    inlineSizeAsset,
  };

  return {
    ...node,
    activeCategory,
    blocks,
    breadcrumbs,
    breakpoints,
    label,
    profileSizes: {},
    gridColumns,
    showHeader,
    showBreadcrumbs,
    shouldShowCategoryDropdown,
    shouldUseSharedFilters,
    shouldShowColorsLink,
    shouldShowGridReviews,
    browserKeyId: getProductBrowserKey(),
    header,
    hideSideBar,
    hideHeader,
    hideHeaderLabel,
    imageType,
    gridCTA,
    nodeIndex: index,
    baseFilters,
    initialFilters,
    initialSortOption,
    initialProducts,
    productRequestParams,
    sortOptions,
    gridAssetContainer,
    gridAssetContainerMobile,
    pageSize,
    productSource,
    routeParams,
    showSort,
  };
}

export function getProductBrowserKey() {
  return Math.floor(Math.random() * 100000);
}

export function createParams({
  initialSortOption,
  filters,
  productSource,
  maxItemCount,
}) {
  return [
    {
      page: 1,
      size: maxItemCount ? Math.min(maxItemCount, pageSize) : pageSize,
      sort: initialSortOption?.sort,
      ...filters,
      // NOTE: restricts product fields returned in order to
      // decrease payload size from API
      productFields: getDefaultProductFields(),
    },
    { apiVersion: 2, productSource },
  ];
}

export async function loadInitialProducts({ params, dispatch }) {
  let initialProducts;
  try {
    const initialProductsResponse = await dispatch(loadProducts(...params));
    initialProducts = initialProductsResponse?.payload;
  } catch (e) {
    initialProducts = {};
    Sentry.captureException(e);
  }

  return initialProducts;
}
