import { getGridNodeArrayFromCollectionsPageBuilderData } from './getGridNodeArrayFromCollectionsPageBuilderData';

export const loadAsyncBuilderComponents = async ({ ctx, builderJson }) => {
  // process the default block as well as the variations
  const blocksToProcess = [
    { url: builderJson?.data.url, blocks: builderJson?.data.blocks },
    ...(builderJson?.variations
      ? Object.keys(builderJson.variations).map(key => {
          return {
            url: builderJson?.data.url,
            blocks: builderJson?.variations[key].data.blocks,
          };
        })
      : []),
  ];

  // multi-thread the processing
  const processedBuilderBlocks = await Promise.all(
    blocksToProcess.map(({ url, blocks }) =>
      processBuilderBlocks({ ctx, url, blocks })
    )
  );

  const collections = processedBuilderBlocks.flatMap(
    ({ collections }) => collections
  );

  const gridLists = processedBuilderBlocks.flatMap(
    ({ gridLists }) => gridLists
  );

  return { collections, gridLists };
};

const mapAllCollections = blocks => {
  const allBlocks = [];

  for (const block of blocks) {
    // since some collections can be wrapped in a Box
    if (!block.component && block.children && block.children.length > 0) {
      allBlocks.push(...mapAllCollections(block.children));
    } else if (
      block.component?.name === '_Tabs_' &&
      Array.isArray(block.component?.options?.tabs) &&
      block.component?.options?.tabs?.length > 0
    ) {
      // since Tabs store their content inside the "tabs -> content" property rather than "children",
      // we map those to see if there are collections nested there
      const tabs = block.component?.options?.tabs.map(tab => tab.content);
      for (const tab of tabs) {
        allBlocks.push(...mapAllCollections(tab));
      }
    } else {
      allBlocks.push(block);
    }
  }

  return allBlocks.filter(
    block =>
      // these components should be considered to avoid unnecessary
      // client side requests, since they all use ProductGrid
      block?.component?.name === '_Collection_' ||
      block?.component?.name === '_RecommendedProducts_'
  );
};

const processBuilderBlocks = async ({ ctx, blocks, url }) => {
  /**
   * Do not attempt to move these methods into builderPageExtension.js or else it will break
   * the app due to circular references found inside of processNodes() in node.js.
   *
   * This logic is consumed inside of BuilderCollection.js but cannot be relocated to that file
   * because processNodes() cannot be executed on the client-side.
   */
  let collections = null;
  if (blocks) {
    // get all collection regardless of Box wrappers
    const allCollections = mapAllCollections(blocks);

    collections = await Promise.all(
      allCollections.map(async ({ component, id }) => {
        const content = await getGridNodeArrayFromCollectionsPageBuilderData({
          ctx,
          name: component.name,
          builderData: {
            url: url,
            ...component.options,
          },
        });
        return {
          id,
          content,
        };
      })
    );
  }

  return { collections };
};
