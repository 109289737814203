export function getProductGridItemFilters({
  categoryIds,
  tagIds: inputTagIds,
  fpls: inputFpls,
  backgroundFplIds: inputBackgroundFplIds,
  backgroundFplCodes: inputBackgroundFplCodes,
  excludeCategoryIds: inputExcludeCategoryIds,
  excludeMasterProductIds: inputExcludeMasterProductIds,
  excludeTagIds: inputExcludeTagIds,
  excludeFpls: inputExcludeFpls,
  productTypeIds: inputProductTypeIds,
  availableQtyMin,
  customFields,
}) {
  // we want to send `list_category: null` in the ProductListViewed
  // tracking event if there are no categories
  const backgroundCategoryIds = categoryIds?.length
    ? parseNodePropertyToArray(categoryIds)
    : null;

  // tagIds is a weird array in an array in the api contract to tier 1
  // which is why it ends up structured as [[tag1, tag2]] different
  // than the rest of the parameters
  const tagIds = inputTagIds?.length
    ? [parseNodePropertyToArray(inputTagIds)]
    : [];
  const fpls = parseNodePropertyToArray(inputFpls);
  const backgroundFplIds = parseNodePropertyToArray(inputBackgroundFplIds);
  const backgroundFplCodes = parseNodePropertyToArray(inputBackgroundFplCodes);
  const excludeCategoryIds = parseNodePropertyToArray(inputExcludeCategoryIds);

  const excludeMasterProductIds = parseNodePropertyToArray(
    inputExcludeMasterProductIds
  );
  const excludeTagIds = parseNodePropertyToArray(inputExcludeTagIds);
  const excludeFpls = parseNodePropertyToArray(inputExcludeFpls);
  const productTypeIds = parseNodePropertyToArray(inputProductTypeIds);

  const baseFilters = {
    backgroundCategoryIds,
    tagIds,
    fpls,
    backgroundFplIds,
    excludeCategoryIds,
    excludeMasterProductIds,
    excludeTagIds,
    excludeFpls,
    productTypeIds,
    imageSize: 1800,
    availableQtyMin: availableQtyMin,
    ...(backgroundFplCodes.length > 0 && { backgroundFplCodes }),
    ...customFields,
  };

  return baseFilters;
}

export function parseNodePropertyToArray(property) {
  if (!property) {
    return [];
  }

  if (Array.isArray(property)) {
    return property.map(item => item.value);
  } else {
    return property.value ? [property.value] : [];
  }
}
