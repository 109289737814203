import type { Component } from '@builder.io/sdk';

const defaultStep = {
  content: [
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      layerName: 'MobileImage',
      component: {
        name: 'Image',
        options: {
          width: 843,
          backgroundSize: 'cover',
          aspectRatio: 1.779,
          fitContent: false,
          lockAspectRatio: false,
          height: 1500,
          backgroundPosition: 'bottom',
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2Fd2e0828ef7454817a018d1fad8f6ab69',
        },
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          '@version': 2,
          layerName: 'Box',
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              layerName: 'A short description...',
              component: {
                name: 'Text',
                options: {
                  text: '<p>Let us help personalize your recommendations...</p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '14px',
                  marginBottom: '14px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                },
                small: { textAlign: 'center', fontSize: '16px' },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              component: {
                name: 'Text',
                options: {
                  text: '<p>TAKE OUR QUIZ TO UNLOCK</p><p><br></p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '24px',
                  marginBottom: '4px',
                  fontWeight: '700',
                  marginTop: 'auto',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                },
                small: { textAlign: 'center', fontSize: '12px' },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              component: {
                name: 'Text',
                options: {
                  text: '<p>Your Savage Xperience.</p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '24px',
                  marginBottom: '4px',
                  fontWeight: '700',
                  marginTop: 'auto',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                },
                small: {
                  fontSize: '28px',
                  textAlign: 'center',
                  marginRight: '12px',
                  marginLeft: '12px',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              meta: {
                localizedTextInputs: ['text'],
              },
              component: {
                name: '_QuizNextButton_',
                options: {
                  variant: 'white',
                  shouldAnimate: true,
                  text: {
                    '@type': '@builder.io/core:LocalizedValue',
                    Default: 'NEXT',
                  },
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  marginTop: '20px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                },
              },
            },
          ],
          responsiveStyles: {
            large: {
              position: 'relative',
              flexShrink: '0',
              boxSizing: 'border-box',
              alignSelf: 'center',
              zIndex: '1',
              flexGrow: '0',
              bottom: '0px',
              width: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: 'auto',
              paddingRight: '16px',
              paddingLeft: '16px',
              paddingBottom: '48px',
              paddingTop: '48px',
              marginTop: 'auto',
              marginRight: 'auto',
              marginLeft: 'auto',
            },
          },
        },
      ],
      responsiveStyles: {
        large: {
          display: 'none',
          flexDirection: 'column',
          flexGrow: '0',
          height: '100%',
          width: '100%',
        },
        medium: { width: '100%', display: 'none' },
        small: { display: 'flex', flexGrow: '0' },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      '@version': 2,
      layerName: 'DesktopImage',
      component: {
        name: 'Image',
        options: {
          image:
            'https://cdn.builder.io/api/v1/image/assets%2F1c781f29dc4a4769ad17cfb27a2f0806%2F82eb7267927c45a0a57a6488c7563f07',
          width: 698,
          backgroundSize: 'cover',
          aspectRatio: 0.808,
          fitContent: false,
          lockAspectRatio: false,
          height: 564,
          backgroundPosition: 'bottom',
        },
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          '@version': 2,
          layerName: 'Box',
          children: [
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              layerName: 'A short description...',
              component: {
                name: 'Text',
                options: {
                  text: '<p>Let us help personalize your recommendations...</p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '16px',
                  marginBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                },
                medium: {
                  fontSize: '16px',
                  marginTop: '0px',
                  marginBottom: '16px',
                },
                small: { paddingRight: '16px' },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              component: {
                name: 'Text',
                options: {
                  text: '<p>TAKE OUR QUIZ TO UNLOCK</p><p><br></p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '12px',
                  marginBottom: '12px',
                  fontWeight: '600',
                  marginTop: 'auto',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              component: {
                name: 'Text',
                options: {
                  text: '<p>Your Savage Xperience.</p>',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  lineHeight: 'normal',
                  height: 'auto',
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: '28px',
                  marginBottom: '0px',
                  fontWeight: '700',
                  marginTop: 'auto',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                },
              },
            },
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              meta: { localizedTextInputs: ['text'] },
              component: {
                name: '_QuizNextButton_',
                options: {
                  variant: 'white',
                  shouldAnimate: true,
                  text: {
                    '@type': '@builder.io/core:LocalizedValue',
                    Default: 'NEXT',
                  },
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  marginTop: '20px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '65%',
                },
              },
            },
          ],
          responsiveStyles: {
            large: {
              position: 'relative',
              flexShrink: '0',
              boxSizing: 'border-box',
              alignSelf: 'center',
              zIndex: '1',
              flexGrow: '0',
              bottom: '0px',
              width: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: 'auto',
              paddingRight: '16px',
              paddingLeft: '16px',
              paddingBottom: '48px',
              paddingTop: '48px',
              marginTop: 'auto',
              marginRight: 'auto',
              marginLeft: 'auto',
            },
          },
        },
      ],
      responsiveStyles: {
        large: {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '0',
          height: '100%',
          width: '100%',
        },
        medium: { width: '100%', display: 'flex' },
        small: { display: 'none' },
      },
    },
  ],
  showNavigation: false,
  showProgress: false,
  showSkipQuiz: false,
};

const defaultContent = [
  {
    '@type': '@builder.io/sdk:Element',
    '@version': 2,
    layerName: 'Text',
    component: {
      name: 'Text',
      options: {
        text: '<h2>Title</h2>',
      },
    },
    responsiveStyles: {
      large: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flexShrink: '0',
        boxSizing: 'border-box',
        marginTop: '16px',
        lineHeight: '34px',
        height: 'auto',
        fontSize: '24px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '58px',
        textAlign: 'center',
        color: 'var(--colors-white, #ffffff)',
      },
    },
  },
  {
    '@type': '@builder.io/sdk:Element',
    '@version': 2,
    layerName: 'Text',
    component: {
      name: 'Text',
      options: {
        text: '<p>Subtitle</p>',
      },
    },
    responsiveStyles: {
      large: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flexShrink: '0',
        boxSizing: 'border-box',
        marginTop: '0px',
        lineHeight: '14px',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '18px',
        fontSize: '12px',
        fontFamily: '"Plain", sans-serif',
        letterSpacing: '1px',
        textAlign: 'center',
        color: 'var(--colors-white, #ffffff)',
        fontWeight: '400',
      },
    },
  },
];

export const BuilderQuizConfig: Component = {
  name: '_Quiz_',
  canHaveChildren: true,
  defaultStyles: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inputs: [
    {
      name: 'skipToStep',
      type: 'number',
      helperText:
        'Step/slide number used as the target for the "Skip Quiz" button if available.',
      defaultValue: null,
    },
    {
      name: 'steps',
      type: 'list',
      copyOnAdd: false,
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: defaultContent,
        },
        {
          name: 'screenName',
          type: 'string',
          required: true,
          helperText: 'Used for naming the screen and url',
        },
        {
          name: 'showProgress',
          helperText:
            'Toggle the progress bar. The steps will be counted starting from the first step where this is enabled.',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'showNavigation',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'navigationTextColor',
          type: 'color',
          helperText: 'Ignored if showNavigation is disabled.',
        },
        {
          name: 'showSkipQuiz',
          type: 'boolean',
          defaultValue: false,
          helperText:
            'Ignored if showNavigation is disabled, or if skipToStep is not valid.',
        },
        {
          name: 'showBackButton',
          type: 'boolean',
          defaultValue: true,
          helperText: 'Ignored if showNavigation is disabled.',
        },
        {
          name: 'backgroundImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fa96c1213fc0a4ee482acf359299eddc0',
        },
        {
          name: 'mobileBackgroundImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fa96c1213fc0a4ee482acf359299eddc0',
        },
      ],
      defaultValue: [defaultStep],
    },
    {
      name: 'exitPath',
      type: 'string',
      helperText: 'The path to redirect to when exiting the quiz.',
      defaultValue: '/features/best-sellers',
    },
  ],
};
