import type { Component } from '@builder.io/sdk';

export const BuilderQuizSizePreferenceConfig: Component = {
  name: '_QuizSizePreference_',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
  },
  inputs: [
    {
      name: 'variant',
      friendlyName: 'Next button variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'productTypesQuestionName',
      type: 'string',
      defaultValue: 'productTypes',
      helperText:
        'The question name used for the related QuizImageSelector component.',
    },
    {
      name: 'braSizeQuestionName',
      type: 'string',
      defaultValue: 'braSize',
    },
    {
      name: 'braSizeAutoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, it proceeds to the next step once an option is selected',
    },
    {
      name: 'braSizeScreenName',
      type: 'string',
      helperText:
        'Used for naming the screen and url, If left empty, the braSizeQuestionName will be used',
    },
    {
      name: 'braSizeIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'braSizeTitle',
      type: 'richText',
      defaultValue: "LET'S FIND THE PERFECT BRA",
      helperText: '',
    },
    {
      name: 'braSizeSubtitle',
      type: 'richText',
      defaultValue: "What's your bra size?",
      helperText: '',
    },
    {
      name: 'braSizeCheckbox',
      type: 'richText',
      defaultValue: 'I need help finding my Savage size',
      helperText: '',
    },
    {
      name: 'bandSizes',
      type: 'list',
      localized: true,
      defaultValue: [
        { size: '30' },
        { size: '32' },
        { size: '34' },
        { size: '36' },
        { size: '38' },
        { size: '40' },
        { size: '42' },
        { size: '44' },
        { size: '46' },
      ],
      subFields: [
        {
          name: 'size',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'cupSizes',
      type: 'list',
      localized: true,
      defaultValue: [
        { size: 'A' },
        { size: 'B' },
        { size: 'C' },
        { size: 'D' },
        { size: 'DD' },
        { size: 'DDD' },
        { size: 'F' },
        { size: 'G' },
        { size: 'H' },
      ],
      subFields: [
        {
          name: 'size',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'braletteSizeQuestionName',
      type: 'string',
      defaultValue: 'braletteSize',
    },
    {
      name: 'braletteSizeAutoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, it proceeds to the next step once an option is selected',
    },
    {
      name: 'braletteSizeScreenName',
      type: 'string',
      helperText:
        'Used for naming the screen and url, If left empty, the braletteSizeQuestionName will be used',
    },
    {
      name: 'braletteSizeIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'braletteSizeTitle',
      type: 'richText',
      defaultValue: "LET'S FIND YOUR PERFECT FIT",
      helperText: '',
    },
    {
      name: 'braletteSizeSubtitle',
      type: 'richText',
      defaultValue: "What's your bralette size?",
      helperText: '',
    },
    {
      name: 'braletteSizeCheckbox',
      type: 'richText',
      defaultValue: 'I need help finding my Savage size',
      helperText: '',
    },
    {
      name: 'braletteSizes',
      type: 'list',
      localized: true,
      defaultValue: [
        {
          size: 'XS',
        },
        {
          size: 'S',
        },
        {
          size: 'M',
        },
        {
          size: 'L',
        },
        {
          size: 'XL',
        },
        {
          size: '1X',
        },
        {
          size: '2X',
        },
        {
          size: '3X',
        },
        {
          size: '4X',
        },
      ],
      subFields: [
        {
          name: 'size',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'undieSizeQuestionName',
      type: 'string',
      defaultValue: 'undieSize',
    },
    {
      name: 'undieSizeScreenName',
      type: 'string',
      helperText:
        'Used for naming the screen and url, If left empty, the undieSizeQuestionName will be used',
    },
    {
      name: 'undieSizeAutoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, it proceeds to the next step once an option is selected',
    },
    {
      name: 'undieSizeIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'undieSizeTitle',
      type: 'richText',
      defaultValue: "LET'S FIND YOUR PERFECT PAIR",
      helperText: '',
    },
    {
      name: 'undieSizeSubtitle',
      type: 'richText',
      defaultValue: "What's your panty or boxer size?",
      helperText: '',
    },
    {
      name: 'undieSizeCheckbox',
      type: 'richText',
      defaultValue: 'I need help finding my Savage size',
      helperText: '',
    },
    {
      name: 'undieSizes',
      type: 'list',
      localized: true,
      defaultValue: [
        {
          size: 'XS',
        },
        {
          size: 'S',
        },
        {
          size: 'M',
        },
        {
          size: 'L',
        },
        {
          size: 'XL',
        },
        {
          size: '1X',
        },
        {
          size: '2X',
        },
        {
          size: '3X',
        },
        {
          size: '4X',
        },
      ],
      subFields: [
        {
          name: 'size',
          type: 'string',
          required: true,
        },
      ],
    },
  ],
};
