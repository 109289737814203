import type { Component } from '@builder.io/sdk';

const onItemsSelectedChange = options => {
  const max = options.get('maxItemsSelected');
  const min = options.get('minItemsSelected');
  if (!isNaN(max) && !isNaN(min) && min > max) {
    options.set('minItemsSelected', max);
  }
};

export const BuilderQuizTextSelectorConfig: Component = {
  name: '_QuizTextSelector_',
  friendlyName: 'TextSelector',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  inputs: [
    {
      name: 'resourceId',
      type: 'string',
      defaultValue: 'productTypes',
      required: true,
    },
    {
      name: 'autoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, after selecting all items or the minimum required items, it advances to the next step.',
    },
    {
      name: 'minimumSelected',
      friendlyName: 'Minimum items selected',
      type: 'number',
      helperText:
        'Minimum number of items that should be selected to trigger autoAdvance.',
      required: true,
      defaultValue: 1,
      showIf: (options: any) => options.get('autoAdvance') === true,
      onChange: (options: any) => {
        if (options.get('minimumSelected') >= options.get('options').length) {
          options.set('minimumSelected', options.get('options').length);
        }
      },
    },
    {
      name: 'options',
      type: 'list',
      subFields: [
        {
          name: 'id',
          type: 'string',
          required: true,
        },
        {
          name: 'label',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'minItemsSelected',
      type: 'number',
      helperText:
        'Minimum number of items that should be selected for the "Next" button to be shown. Should be lower than maxItemsSelected. If left empty, 1 will be used.',
      defaultValue: 1,
      onChange: onItemsSelectedChange,
    },
    {
      name: 'maxItemsSelected',
      type: 'number',
      helperText:
        'Maximum number of items that should be selected for the "Next" button to be shown. If left empty, value will be ignored.',
      onChange: onItemsSelectedChange,
    },
    {
      name: 'showSelectAll',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'selectAllLabel',
      type: 'string',
      defaultValue: 'All of them',
    },
    {
      name: 'nextButtonLabel',
      type: 'string',
      defaultValue: 'Next',
    },
    {
      name: 'variant',
      friendlyName: 'Next button variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'shouldAnimate',
      type: 'boolean',
      helperText: 'Enable or disable the "Next" button animation.',
      defaultValue: true,
    },
  ],
};
