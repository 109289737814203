import builder from '@builder.io/react';

import { getBuilderPageTargeting } from './getBuilderPageTargeting';
import { isBuilderPreview } from './isBuilderPreview';

export const getBuilderJson = async (
  { modelName, urlPath, query = {} },
  ctx
) => {
  const { userAttributes, options } = await getBuilderPageTargeting({
    state: ctx.store.getState(),
    isPreview: isBuilderPreview(ctx),
    query,
    dispatch: ctx.store.dispatch,
  });

  const builderJson = await builder
    .get(modelName, {
      userAttributes: {
        urlPath,
        ...userAttributes,
      },
      options,
      query,
    })
    .promise();

  return { builderJson };
};
