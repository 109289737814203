import type { Component } from '@builder.io/sdk';

export const BuilderFeatureFlagWrapperConfig: Component = {
  name: '_FeatureFlagWrapper_',
  noWrap: true,
  canHaveChildren: true,
  static: true,
  defaultStyles: {
    marginTop: '0',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  inputs: [
    {
      name: 'content',
      type: 'uiBlocks',
      defaultValue: [],
    },
    {
      name: 'name',
      type: 'string',
      required: true,
    },
    {
      name: 'flagKey',
      type: 'string',
      required: true,
    },
    {
      name: 'variationValue',
      type: 'string',
      required: false,
      helperText:
        'For boolean flags, true is assumed unless "false" is entered',
    },
  ],
};
