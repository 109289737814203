import config from 'config';

export const ProductCategory = config.get('public.productCategories');
export const ProductTag = config.get('public.productTags');

function isCurvyPage(category) {
  // The URL constructor requires a hostname, but we're given a relative path,
  // so just use an example host to make this work; we're only interested in the
  // resulting `searchParams` anyway.
  const url = new URL(category.urlPath, 'https://example.com/');
  // This can return NaN, but it's fine, because we're only using it for a
  // comparison anyway, so it'll just return false;
  const defaultTagId = parseInt(url.searchParams.get('defaultTagId'), 10);
  return defaultTagId === ProductTag.plusSize;
}

export function getPreservedFilters(filters, category) {
  if (isCurvyPage(category)) {
    // Exclude size filters from those that will be preserved in the URL.
    const newAggregationFilter = Object.keys(filters.aggregationFilter).reduce(
      (aggregationFilter, field) => {
        if (field !== 'size' && !field.startsWith('size_')) {
          aggregationFilter[field] = filters.aggregationFilter[field];
        }
        return aggregationFilter;
      },
      {}
    );
    return {
      ...filters,
      aggregationFilter: newAggregationFilter,
    };
  }
  return filters;
}
