import React, { useCallback } from 'react';

import { BuilderComponent as BaseBuilderComponent } from '@builder.io/react';
import { useDispatch } from 'react-redux';

import { builderTestEntered } from '../../utils/builderTestEntered';

/**
 * Decorator/Wrapper component that wraps the original Builder Component and adds functionalities that should be present in all instances of BuilderComponent. You should probably use this.
 */
const BuilderComponent = (
  props: React.ComponentProps<typeof BaseBuilderComponent>
) => {
  const dispatch = useDispatch();
  const { contentLoaded } = props;

  const handleContentLoaded = useCallback(
    (pageContentData, pageContent) => {
      // track Builder AB test with Segment
      builderTestEntered(dispatch, pageContent);

      if (contentLoaded) {
        contentLoaded(pageContentData, pageContent);
      }
    },
    [contentLoaded, dispatch]
  );

  return (
    <BaseBuilderComponent {...props} contentLoaded={handleContentLoaded} />
  );
};

export default BuilderComponent;
