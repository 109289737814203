import type { Component } from '@builder.io/sdk';

export const BuilderCountdownTimerConfig: Component = {
  name: '_CountdownTimer_',
  canHaveChildren: true,
  defaultStyles: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inputs: [
    {
      name: 'text',
      type: 'string',
      helperText: 'Enter the text to display in the countdown timer.',
      defaultValue: 'Offer Ends In',
    },
    {
      name: 'textColor',
      type: 'color',
      helperText: 'Choose the color for the text.',
      defaultValue: '#ffffff',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      helperText: 'Select a background color for the countdown timer.',
      defaultValue: '#18181e',
    },
  ],
};
