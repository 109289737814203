import {
  Builder,
  withChildren as withChildrenWrapper,
} from '@builder.io/react';
import dynamic from 'next/dynamic';

import { SXFBuilderAccordionConfig } from '../components/BuilderAccordion/BuilderAccordion.builder';
import { BuilderAssetConfig } from '../components/BuilderAsset/BuilderAsset.builder';
import { BuilderCarouselConfig } from '../components/BuilderCarousel/BuilderCarousel.builder';
import { BuilderCollectionConfig } from '../components/BuilderCollection/BuilderCollection.builder';
import { BuilderCountdownTimerConfig } from '../components/BuilderCountdownTimer/BuilderCountdownTimer.builder';
import { BuilderCTAConfig } from '../components/BuilderCTA/BuilderCTA.builder';
import { BuilderFeaturedProductConfig } from '../components/BuilderFeaturedProduct/BuilderFeaturedProduct.builder';
import { BuilderFeatureFlagWrapperConfig } from '../components/BuilderFeatureFlagWrapper/BuilderFeatureFlagWrapper.builder';
import { BuilderPrivacyFormWrapperConfig } from '../components/BuilderPrivacyFormWrapper/BuilderPrivacyFormWrapper.builder';
import { BuilderQuizConfig } from '../components/BuilderQuiz/BuilderQuiz.builder';
import { BuilderQuizCompleteConfig } from '../components/BuilderQuizComplete/BuilderQuizComplete.builder';
import { BuilderQuizImageSelectorConfig } from '../components/BuilderQuizImageSelector/BuilderQuizImageSelector.builder';
import { BuilderQuizListSelectorConfig } from '../components/BuilderQuizListSelector/BuilderQuizListSelector.builder';
import { BuilderQuizNextButtonConfig } from '../components/BuilderQuizNextButton/BuilderQuizNextButton.builder';
import { BuilderQuizReferralsConfig } from '../components/BuilderQuizReferrals/BuilderQuizReferrals.builder';
import { BuilderQuizSizePreferenceConfig } from '../components/BuilderQuizSizePreference/BuilderQuizSizePreference.builder';
import { BuilderQuizTextSelectorConfig } from '../components/BuilderQuizTextSelector/BuilderQuizTextSelector.builder';
import { BuilderRecommendedProductsConfig } from '../components/BuilderRecommendedProducts/BuilderRecommendedProducts.builder';
import { BuilderTabsConfig } from '../components/BuilderTabs/BuilderTabs.builder';
import { BuilderTrackingWrapperConfig } from '../components/BuilderTrackingWrapper/BuilderTrackingWrapper.builder';
import { BuilderTextConfig } from '../components/BuilderTypography/BuilderText.builder';
import { BuilderUGCConfig } from '../components/BuilderUGC/BuilderUGC.builder';
import { BuilderVIPMembershipConfig } from '../components/BuilderVIPMembership/BuilderVIPMembership.builder';
import { BuilderGridFilterBarConfig } from '../components/GridFilterBar/GridFilterBar.builder';
import { generateBuilderDesignTokenColors } from '../styles/utils';

export const filterBarComponents = [
  [
    dynamic(() =>
      import('../components/GridFilterBar').then(
        module => module.BuilderGridFilterBar
      )
    ),
    BuilderGridFilterBarConfig,
  ],
];

export const nodeComponents = [
  [
    dynamic(() => import('../components/BuilderCountdownTimer')),
    BuilderCountdownTimerConfig,
  ],
  [dynamic(() => import('../components/BuilderAsset')), BuilderAssetConfig],
  [dynamic(() => import('../components/BuilderCTA')), BuilderCTAConfig],
  [
    dynamic(() => import('../components/BuilderCollection')),
    BuilderCollectionConfig,
  ],
  [dynamic(() => import('../components/BuilderUGC')), BuilderUGCConfig],
  [
    dynamic(() => import('../components/BuilderCarousel')),
    BuilderCarouselConfig,
  ],
  [
    dynamic(() => import('../components/BuilderFeaturedProduct')),
    BuilderFeaturedProductConfig,
  ],
  [
    dynamic(() => import('../components/BuilderFeatureFlagWrapper')),
    BuilderFeatureFlagWrapperConfig,
  ],
  [dynamic(() => import('../components/BuilderTabs')), BuilderTabsConfig],
  [
    dynamic(() => import('../components/BuilderRecommendedProducts')),
    BuilderRecommendedProductsConfig,
  ],
  [
    dynamic(() => import('../components/BuilderAccordion')),
    SXFBuilderAccordionConfig,
  ],
  [
    dynamic(() => import('../components/BuilderTrackingWrapper')),
    BuilderTrackingWrapperConfig,
  ],
  [
    dynamic(() =>
      import('../components/BuilderTypography/BuilderText').then(
        module => module.BuilderText
      )
    ),
    BuilderTextConfig,
  ],
  [
    dynamic(() => import('../components/BuilderVIPMembership')),
    BuilderVIPMembershipConfig,
  ],
  [
    dynamic(() => import('../components/BuilderPrivacyFormWrapper')),
    BuilderPrivacyFormWrapperConfig,
  ],

  ...filterBarComponents,
];

export const quizComponents = [
  [dynamic(() => import('../components/BuilderQuiz')), BuilderQuizConfig],
  [
    dynamic(() => import('../components/BuilderQuizNextButton')),
    BuilderQuizNextButtonConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizListSelector')),
    BuilderQuizListSelectorConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizTextSelector')),
    BuilderQuizTextSelectorConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizComplete')),
    BuilderQuizCompleteConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizImageSelector')),
    BuilderQuizImageSelectorConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizSizePreference')),
    BuilderQuizSizePreferenceConfig,
  ],
  [
    dynamic(() => import('../components/BuilderQuizReferrals')),
    BuilderQuizReferralsConfig,
  ],
];

export const allComponents = [...nodeComponents, ...quizComponents];

export function registerBuilderComponents(components = []) {
  components.forEach(component => {
    if (Array.isArray(component)) {
      const [importedComponent, builderConfig] = component;
      const { canHaveChildren, ...builderProps } = builderConfig;
      Builder.registerComponent(
        canHaveChildren
          ? withChildrenWrapper(importedComponent)
          : importedComponent,
        builderProps
      );
    } else {
      const { withChildren, ...builderProps } = component.builderComponent;
      Builder.registerComponent(
        withChildren ? withChildrenWrapper(component) : component,
        builderProps
      );
    }
  });

  Builder.register('editor.settings', {
    allowOverridingTokens: true,
    designTokens: {
      colors: generateBuilderDesignTokenColors(),
    },
  });
}

export function registerBuilderNodesMenu() {
  Builder.register('insertMenu', {
    name: 'Nodes',
    items: [
      { name: '_Asset_' },
      { name: '_Cta_' },
      { name: '_BrandedText_' },
      { name: '_Collection_' },
      { name: '_Ugc_' },
      { name: '_Carousel_' },
      { name: '_FeaturedProduct_' },
      { name: '_Tabs_' },
      { name: '_RecommendedProducts_' },
      { name: '_Accordion_' },
      { name: '_TrackingWrapper_' },
      { name: '_FeatureFlagWrapper_' },
      { name: '_CountdownTimer_' },
      { name: '_VIPMembership_' },
      { name: '_BuilderPrivacyFormWrapper_' },
    ],
  });
}

export function registerBuilderQuizMenu() {
  Builder.register('insertMenu', {
    name: 'Quiz',
    items: [
      { name: '_Quiz_' },
      { name: '_QuizNextButton_' },
      { name: '_QuizListSelector_' },
      { name: '_QuizTextSelector_' },
      { name: '_QuizComplete_' },
      { name: '_QuizImageSelector_' },
      { name: '_QuizSizePreference_' },
      { name: '_QuizReferrals_' },
    ],
  });
}
