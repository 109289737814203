import { transformV2RequestToGqlQuery } from '@techstyle/graphql-util';
import {
  gql,
  parseGqlProductsToV2,
  transformDimensions,
} from '@techstyle/react-graphql';

/**
 * @typedef {import('@techstyle/graphql-util').V2Request} V2Request
 * @typedef {import('@techstyle/graphql-util').ConvertOptions} ConvertOptions
 * @typedef {import('@apollo/client').ApolloClient} ApolloClient
 */

/**
 * Utiliy function to help northstar data fetching easier. This function abstracts the multi part process that goes into making a v2 like query
 * to the northstar gql api
 *
 * @param {ApolloClient} client Apollo graphql client
 *
 * @param {V2Request} v2Params V2 api params that will get converted to used to generate the gql query and variable
 *
 * @param {ConvertOptions | undefined} options Northstar util specific options. Some options are to  exclusively fetch for products, product filters, or both
 *
 * @returns Product data that should be the exact same shape a v2 response
 */
export const loadNorthStarProducts = async (
  client,
  v2Params = {},
  options = {
    includeProductDimensions: false,
    includeProductListing: true,
    includePricing: true,
    deferPricing: true,
  }
) => {
  const { query: gqlQuery, variables } = transformV2RequestToGqlQuery(
    v2Params,
    options
  );

  const {
    data: { productDimensions, productListing },
  } = await client.query({
    context: { clientName: 'supergraph' },
    query: gql`
      ${gqlQuery}
    `,
    variables,
  });

  const { from, size } = productListing;

  const gqlResponse = {
    products: productListing?.products
      ? parseGqlProductsToV2(productListing?.products)
      : [],
    total: productListing?.total?.value ?? 0,
    pages: Math.floor(productListing?.total?.value / v2Params.size),
    page: Math.floor(from / size) + 1,
    pageSize: v2Params.size,
    aggregations: productDimensions
      ? transformDimensions(productDimensions.dimensions)
      : [],
  };

  return gqlResponse;
};
