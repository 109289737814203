import { trackBuilderTestEntered } from '../actions/builder';

export function builderTestEntered(dispatch, content) {
  const contentHasVariation = content?.testRatio && content?.testRatio < 1;

  if (contentHasVariation) {
    dispatch(
      trackBuilderTestEntered({
        ...content,
      })
    );
  }
}
