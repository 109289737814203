// function to retrieve the grid data around the category side nav
export function getProductGridItemCategoryNavSettings({ node }) {
  return {
    activeCategory: {
      ancestors: [],
      siblings: [],
      isTopLevel: true,
      label: 'category',
      isResourceBundle: false,
      subcategories: [],
      urlPath: '/',
    },
    breadcrumbs: null,
    shouldShowCategoryDropdown:
      node.customFields?.shouldShowCategoryDropdown || false,
  };
}
