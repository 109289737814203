export const BuilderQuizReferralsConfig = {
  name: '_QuizReferrals_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  defaultStyles: {
    minHeight: '0',
    maxHeight: '450px',
    flex: '1',
  },
  inputs: [
    {
      name: 'id',
      type: 'string',
      required: true,
      defaultValue: 'optionId',
    },
    {
      name: 'autoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, it proceeds to the next step once an option is selected',
    },
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'NEXT',
      localized: true,
    },
    {
      name: 'shouldAnimate',
      type: 'boolean',
      defaultValue: true,
    },
  ],
};
