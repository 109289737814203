import type { Component } from '@builder.io/sdk';

export const SXFBuilderAccordionConfig: Component = {
  name: '_Accordion_',
  friendlyName: 'Accordion',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  inputs: [
    {
      name: 'items',
      type: 'list',
      defaultValue: [
        {
          label: 'How much does the Xtra VIP Membership cost?',
          textBlock: [
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              id: 'builder-939cb78a82624152b181eaa26b0dfeeb',
              component: {
                name: 'Text',
                options: {
                  text: "There's no monthly cost to get access to Everyday Xtra VIP Perks if you log in to your account and click \"Skip This Month\" between the 1st and the 5th of each month. On the 6th of each month, if you haven't chosen to 'Skip the Month,' your payment method will be charged $59.95, which unlocks Xclusive Perks like Xclusive Bundles and access to even more Xtra VIP-only Sets and colors. In addition, you will also receive a promotional Member Credit for free with monthly billing. Each Member Credit can be redeemed for ANY item, Xtra VIP Box, Set, or Xclusive Bundle — which means savings of up to 50% on full-price styles! Member Credits can be redeemed on future purchases and expire after 12 months. But don't worry, we'll remind you ahead of time! You'll always enjoy Everyday Xtra VIP Perks & pricing, even if you skip a month. Skip as many times as you like—there is no limit. ",
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  marginTop: '0px',
                  height: 'auto',
                  paddingTop: '8px',
                  paddingLeft: '8px',
                  paddingBottom: '8px',
                  paddingRight: '8px',
                  marginBottom: '24px',
                  marginLeft: '40px',
                  fontSize: '10pt',
                  lineHeight: '20px',
                  fontFamily: 'Arial, sans-serif',
                },
              },
            },
          ],
        },
        {
          label:
            "Do I have to buy something every month if I'm an Xtra VIP Member?",
          textBlock: [
            {
              '@type': '@builder.io/sdk:Element',
              '@version': 2,
              id: 'builder-939cb78a82624152b181eaa26b0dfeeb',
              component: {
                name: 'Text',
                options: {
                  text: 'Nope! The Xtra VIP Membership is flexible. Just log in to your account and click "Skip This Month" between the 1st and the 5th of each month, and you won\'t be charged $59.95 that month to unlock Xclusive Perks. You can come back and shop anytime and enjoy Everyday Xtra VIP Perks & pricing, even if you skip a month. ',
                },
              },
              responsiveStyles: {
                large: {
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  flexShrink: '0',
                  boxSizing: 'border-box',
                  marginTop: '0px',
                  height: 'auto',
                  paddingTop: '8px',
                  paddingLeft: '8px',
                  paddingBottom: '8px',
                  paddingRight: '8px',
                  marginBottom: '24px',
                  marginLeft: '40px',
                  fontSize: '10pt',
                  lineHeight: '20px',
                  fontFamily: 'Arial, sans-serif',
                },
              },
            },
          ],
        },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
          required: true,
        },
        {
          name: 'textBlock',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
    },
  ],
};
