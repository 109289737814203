import { useMemo, useCallback, useEffect, useState } from 'react';

import builder from '@builder.io/react';
import { useQuery } from '@tanstack/react-query';

import { useBuilderTargetingOptions } from './useBuilderPageTargeting';

const defaultQuery = {};

export const useBuilderPageContent = ({
  modelName,
  urlPath,
  query = defaultQuery,
  shouldRefreshOnLogin = true,
  page,
}) => {
  const { userAttributes, options, isLoadingCustomer } =
    useBuilderTargetingOptions();
  const [isSsrPage, setIsSsrPage] = useState(!!page);
  const builderOptions = useMemo(
    () => ({
      userAttributes: {
        urlPath,
        ...userAttributes,
      },
      options,
      query,
    }),
    [query, options, userAttributes, urlPath]
  );

  const builderKey = useMemo(() => {
    if (!shouldRefreshOnLogin) {
      return {
        ...builderOptions,
        userAttributes: {
          ...(builderOptions.userAttributes || {}),
          loggedIn: null,
          isLoggedIn: null,
          membershipStatus: null,
        },
      };
    }
    return {
      ...builderOptions,
      modelName,
    };
  }, [builderOptions, modelName, shouldRefreshOnLogin]);

  const fetchBuilderData = useCallback(async () => {
    try {
      const builderJson = await builder
        .get(modelName, builderOptions)
        .promise();

      return builderJson;
    } catch (err) {
      return undefined;
    }
  }, [modelName, builderOptions]);

  const { data: pageContent, isLoading: isLoadingQuery } = useQuery({
    queryKey: ['builder-content', builderKey],
    queryFn: () => fetchBuilderData(),
    // use only data from ssr on the first load
    initialData: isSsrPage ? page : undefined,
    enabled: !isLoadingCustomer,
  });

  useEffect(() => {
    if (isSsrPage) {
      setIsSsrPage(false);
    }
  }, []);

  return {
    pageContent,
    // Wait until membershipStatus and useQuery has completed loaded
    isLoading: isLoadingQuery || isLoadingCustomer,
  };
};
