import { loadAssets } from '@techstyle/react-assets';

export const ssrPreprocessBuilderJson = async (ctx, builderJson) => {
  // We'll preprocess the blocks added to the page for any custom component pre-processing
  // so return if there is nothing defined in the custom area
  if (!builderJson?.data?.blocks) {
    return;
  }

  const assetsToLoad = [];
  const addAssetsToLoad = assets => {
    if (!Array.isArray(assets)) {
      assets = [assets];
    }

    assets.forEach(asset => {
      if (asset) {
        assetsToLoad.push(asset);
      }
    });
  };

  for (const block of builderJson.data.blocks) {
    const blockType = block?.component?.name;

    switch (blockType) {
      case '_Collection_':
      case '_RecommendedProducts_':
        addAssetsToLoad([
          block.component?.options?.gridAssetContainer,
          block.component?.options?.gridAssetContainerMobile,
        ]);
        break;
    }
  }

  if (assetsToLoad.length > 0) {
    await ctx.store.dispatch(loadAssets(assetsToLoad));
  }
};
