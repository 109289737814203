import { captureException } from '@sentry/nextjs';
import config from 'config';

import { getMembership } from '@techstyle/react-accounts';
import { ProductType } from '@techstyle/react-products';
import {
  createSelector,
  getNewDateFunction,
  parseDate,
  toPacificDateString,
} from '@techstyle/redux-core';

import { ProductTag } from './productCategories';
import { SegmentationOptions, SEGMENTATION_OPTIONS } from './segmentation';
import { isProductSet } from './selectors';

const vipAutoSellOut = config.get('public.vipAutoSellOut');
const warehouseIdsByRegion = config.get(
  'public.quantityRules.warehouseIdsByRegion'
);
const locationLowStockIndicator = config.get(
  'public.quantityRules.locationLowStockIndicator'
);

type SortOptionValueObject = {
  field: string;
  direction: 'asc' | 'desc';
};

type SortOptionValue = string | SortOptionValueObject[];

type SortOption = {
  sort: SortOptionValue;
  value: string;
  defaultMessage?: string;
  isDefault?: boolean;
};

export const defaultSortOptions: SortOption[] = [
  {
    value: 'newest',
    isDefault: true,
    sort: 'newest',
    defaultMessage: 'New Arrivals',
  },
  {
    value: 'bestsellers',
    sort: 'bestsellers',
    defaultMessage: 'Best Sellers',
  },
  {
    value: 'pricel2h',
    sort: 'pricel2h',
    defaultMessage: 'Price: Low to High',
  },
  {
    value: 'priceh2l',
    sort: 'priceh2l',
    defaultMessage: 'Price: High to Low',
  },
];

export const pageSize = 24;

export const fplSortOption: SortOption = {
  value: 'fplasc',
  sort: 'fplasc',
  defaultMessage: 'Default',
  isDefault: true,
} as const;

export const getTagIdSet = createSelector(
  [(state, props) => props.product],
  product => new Set(product.tagIdList)
);

// check for fake sold out tag, then auto sell out VIP box product
export const getIsAutoSoldOut = createSelector(
  [getNewDateFunction, getTagIdSet],
  (newDate, tagIdSet) => {
    if (tagIdSet.has(ProductTag.fakeSoldOut)) {
      // only apply auto sell out on xtraVipBox
      if (
        tagIdSet.has(ProductTag.xtraVipBox) &&
        vipAutoSellOut.enabled &&
        vipAutoSellOut.date != null
      ) {
        const pacificDayString = toPacificDateString(newDate(), 'D');
        const pacificDay = parseInt(pacificDayString, 10);

        return pacificDay >= vipAutoSellOut.date;
      } else {
        return true;
      }
    }

    return false;
  }
);

export const getIsPreorder = createSelector(
  [getNewDateFunction, (state, props) => props.product.datePreorderExpires],
  (newDate, datePreorderExpires) => {
    if (!datePreorderExpires) {
      return false;
    }

    const productPreorderExpiryDate = parseDate(datePreorderExpires);
    const currentDate = newDate();

    return productPreorderExpiryDate.getTime() > currentDate.getTime();
  }
);

export const getIsMemberTypeSoldOut = createSelector(
  [getMembership, getTagIdSet],
  (membership, tagIdSet) => {
    const { isLead, isVip } = membership;
    if (isLead) {
      return tagIdSet.has(ProductTag.soldOutLead);
    } else if (isVip) {
      return tagIdSet.has(ProductTag.soldOutVip);
    }

    return false;
  }
);

export const getIsSoldOut = createSelector(
  [
    (state, props) => props.product,
    getTagIdSet,
    getIsAutoSoldOut,
    getIsMemberTypeSoldOut,
    getIsPreorder,
  ],
  (product, tagIdSet, isAutoSoldOut, isMemberTypeSoldOut, isPreorder) => {
    if (isMemberTypeSoldOut) {
      return true;
    }

    if (isAutoSoldOut) {
      return true;
    }

    if (
      isProductSet(product) &&
      !(product.products_in_set || product.productsInSet)
    ) {
      return false;
    }

    if (product.productTypeId === ProductType.BUNDLE) {
      // TODO: remove after `availableQuantityAnyProfile` DB fix.
      if (product.productOptionProfileIdObjectList?.[0]) {
        return (
          product.productOptionProfileIdObjectList[0].availableQuantity <= 0
        );
      }

      // TODO: This field is always 0. It must be fixed by DB Ops.
      if (product.availableQuantityAnyProfile != null) {
        return product.availableQuantityAnyProfile <= 0;
      }

      return false;
    }

    const actualQuantity = isPreorder
      ? product.availableQuantityPreorderMaster
      : product.availableQuantityMaster;
    return actualQuantity <= 0;
  }
);

/* Warehouse
 * warehouse ids map to a specific region. regions determine low stock thresholds.
 */
export const getWarehouseRegion = createSelector(
  [state => state.borderfree.isBorderfreeCustomer, state => state.domain.tld],
  (isBorderfreeCustomer, tld) => {
    if (isBorderfreeCustomer) {
      return 'BF';
    } else if (tld === '.com') {
      return 'US';
    } else if (tld === '.co.uk') {
      return 'UK';
    }
    return 'EU';
  }
);

export const getWarehouseIds = createSelector(
  [getWarehouseRegion],
  region => new Set(warehouseIdsByRegion[region])
);

export const getWarehouseQuantity = createSelector(
  [(state, props) => props.product, getWarehouseIds, getIsSoldOut],
  (product, warehouseIds, isSoldOut) => {
    if (isSoldOut) {
      return 0;
    }

    const warehouses = product.warehouseIdObjectList;
    if (warehouses == null) {
      return product.availableQuantityMaster;
    }

    const quantity = warehouses
      .filter(warehouse => warehouseIds.has(warehouse.warehouseId))
      .reduce((total, warehouse) => warehouse.availableQuantity + total, 0);

    return Math.max(0, quantity);
  }
);

export const getMasterProductLowStockRules = createSelector(
  [getWarehouseRegion],
  warehouseRegion => locationLowStockIndicator.masterProduct[warehouseRegion]
);

export const getIsCriticalLowStock = createSelector(
  [
    (state, props) => props.product,
    getIsSoldOut,
    getWarehouseQuantity,
    getMasterProductLowStockRules,
  ],
  (product, isSoldOut, warehouseQuantity, lowStockRules) => {
    if (isSoldOut) {
      return false;
    }
    if (product.productTypeId === ProductType.BUNDLE) {
      return false;
    }
    if (warehouseQuantity === 0) {
      return false;
    }
    return warehouseQuantity <= lowStockRules.criticalStockMax;
  }
);

export const getIsLowStock = createSelector(
  [
    (state, props) => props.product,
    getIsSoldOut,
    getWarehouseQuantity,
    getMasterProductLowStockRules,
  ],
  (product, isSoldOut, warehouseQuantity, lowStockRules) => {
    if (isSoldOut) {
      return false;
    }
    if (product.productTypeId === ProductType.BUNDLE) {
      return false;
    }
    if (warehouseQuantity === 0) {
      return false;
    }
    if (
      warehouseQuantity <= lowStockRules.lowStockMax &&
      warehouseQuantity >= lowStockRules.criticalStockMax
    ) {
      return true;
    }
  }
);

export const addNewDefaultSortOption = (option = {}, sortOptions = []) => {
  return [
    option,
    ...sortOptions.map(option => ({
      ...option,
      isDefault: false,
    })),
  ];
};

type PotentiallyExtendedSortOptions =
  | SortOption
  | SortOption[]
  | {
      behavior?: 'prepend' | 'append' | 'replace';
      options: SortOption | SortOption[];
    };

type SegmentedSortOptions = {
  default?: PotentiallyExtendedSortOptions;
  [key: string]: PotentiallyExtendedSortOptions | undefined;
} & Partial<Record<SegmentationOptions, PotentiallyExtendedSortOptions>>;

type SegmentedSortValue = {
  default?: SortOptionValue;
  [key: string]: SortOptionValue | undefined;
} & Partial<Record<SegmentationOptions, SortOptionValue>>;

const extendedSegmentedOptions = [...SEGMENTATION_OPTIONS, 'default'] as const;

const isSegmentedOptions = (
  sortOptions: any
): sortOptions is SegmentedSortOptions => {
  if (typeof sortOptions !== 'object') {
    return false;
  }

  return Object.keys(sortOptions).some(key => {
    const segments = key.split(',');
    return extendedSegmentedOptions.includes(segments[0] as any);
  });
};

const isSegmentedValue = (sortValue: any): sortValue is SegmentedSortValue => {
  if (typeof sortValue !== 'object') {
    return false;
  }

  return Object.keys(sortValue).some(key =>
    extendedSegmentedOptions.includes(key as any)
  );
};

const removeDuplicateSortOptions = (sortOptions: SortOption[]) => {
  const uniqueOptions = new Set();
  return sortOptions.filter(option => {
    // Remove if the sort option or default message is a duplicate
    const key = typeof option.sort === 'string' ? option.sort : option.value;
    const message = option.defaultMessage;

    const isDuplicate = uniqueOptions.has(key) || uniqueOptions.has(message);
    uniqueOptions.add(key);
    uniqueOptions.add(message);

    return !isDuplicate;
  });
};

const getSortOptions = ({
  sortOptions,
  defaultOptions = defaultSortOptions,
}: {
  sortOptions: PotentiallyExtendedSortOptions;
  defaultOptions?: SortOption[];
}): SortOption[] => {
  if (sortOptions === null) {
    return [];
  }

  if (Array.isArray(sortOptions)) {
    return sortOptions;
  }

  if (typeof sortOptions === 'object' && 'options' in sortOptions) {
    let sortOptionsFromExtended = getSortOptions({
      sortOptions: sortOptions.options,
    });

    if (sortOptions.behavior === 'replace') {
      return sortOptionsFromExtended;
    }

    // If there is a default option in the new sort options, we need to remove the default from the default options
    // Additionally, if we are prepending only one option it will be considered the default.
    const hasNewDefault =
      sortOptionsFromExtended.some(({ isDefault }) => isDefault) ||
      (sortOptions.behavior !== 'append' &&
        sortOptionsFromExtended.length === 1);

    // Necessary so we don't mess up the original reference
    let newDefaultOptions = [...defaultOptions];

    if (hasNewDefault) {
      newDefaultOptions = newDefaultOptions.map(option => ({
        ...option,
        isDefault: false,
      }));
    }

    if (sortOptions.behavior === 'append') {
      return removeDuplicateSortOptions([
        ...newDefaultOptions,
        ...sortOptionsFromExtended,
      ]);
    }

    if (sortOptionsFromExtended.length === 1) {
      // Necessary so we don't mess up the original reference
      sortOptionsFromExtended = [
        {
          ...sortOptionsFromExtended[0],
          defaultMessage:
            sortOptionsFromExtended[0].defaultMessage ?? 'Default',
          isDefault: true,
        },
      ];
    }

    // 'prepend' is the default behavior
    return removeDuplicateSortOptions([
      ...sortOptionsFromExtended,
      ...newDefaultOptions,
    ]);
  }

  return [sortOptions];
};

// It's possible for a key to be a comma separated list of segments, this will separate them into an array
const separateSegmentedOptions = <
  T extends SegmentedSortOptions | SegmentedSortValue
>(
  options: T
) => {
  const separatedOptions = {} as T;

  Object.keys(options).forEach(key => {
    const segments = key.split(',');
    segments.forEach(segment => {
      separatedOptions[segment] = options[key];
    });
  });

  return separatedOptions;
};

export const getSegmentedSortOptions = ({
  potentialSegments = [],
  sortOptions,
  initialSortOptions,
  initialSortValue,
}: {
  potentialSegments?: SegmentationOptions[];
  sortOptions?: SegmentedSortOptions | SortOption[] | SortOption;
  initialSortOptions: SortOption[];
  initialSortValue?: SortOptionValue | SegmentedSortValue;
}) => {
  const hasSegmentedSortValue = isSegmentedValue(initialSortValue);
  const hasSegmentedSortOptions = isSegmentedOptions(sortOptions);
  const nonSegmentedSortOptions = !hasSegmentedSortOptions
    ? getSortOptions({
        sortOptions: sortOptions ?? initialSortOptions,
      })
    : null;

  if (!hasSegmentedSortValue && !hasSegmentedSortOptions) {
    return {
      sortOptions: nonSegmentedSortOptions,
      sortValue: initialSortValue,
    };
  }

  let sortValue = hasSegmentedSortValue
    ? initialSortValue?.default
    : initialSortValue;

  if (hasSegmentedSortValue) {
    const separatedSortValue = separateSegmentedOptions(initialSortValue);
    potentialSegments.some(segment => {
      const segmentedValue = separatedSortValue[segment];
      if (segmentedValue) {
        sortValue = segmentedValue;
        return true;
      }
    });
  }

  if (!hasSegmentedSortOptions) {
    return {
      sortOptions: nonSegmentedSortOptions,
      sortValue,
    };
  }

  const defaultOptions = sortOptions.default
    ? getSortOptions({
        sortOptions: sortOptions.default,
        defaultOptions: initialSortOptions,
      })
    : initialSortOptions;

  let segmentedSortOptions = defaultOptions;

  potentialSegments.some(segment => {
    const separatedSortOptions = separateSegmentedOptions(sortOptions);
    const segmentedOptions = separatedSortOptions[segment];
    if (segmentedOptions) {
      segmentedSortOptions = getSortOptions({
        sortOptions: segmentedOptions,
        defaultOptions,
      });
      return true;
    }
  });

  return {
    sortOptions: segmentedSortOptions,
    sortValue,
  };
};

type SegmentedFeatureSort = {
  sort?: SegmentedSortValue | SortOptionValue;
  sortOptions?: SegmentedSortOptions | SortOption[];
};

export const attachRewriteFeatureSortToSortOptions = (
  feature,
  initialSortOptions: SortOption[],
  potentialSegments?: SegmentationOptions[]
) => {
  let initialSortOption: SortOption | undefined;
  let sortOptions = [...initialSortOptions];

  const { value = '{}' } = feature || {};

  let rewriteFeatureData = {} as { sort?: any };

  try {
    if (typeof value === 'string' && value !== '{}') {
      rewriteFeatureData = JSON.parse(value);
    }
  } catch (e) {
    // Unable to parse json
    captureException(e);
  }

  const { sort: rewriteSort = '', sortOptions: rewriteSortOptions } =
    rewriteFeatureData as SegmentedFeatureSort;
  const hasSegmentedRewriteSort = isSegmentedValue(rewriteSort);

  // NOTE: This is the legacy behavior that only supports adding/replacing a default sort option
  // The sort from the rewrites will always take precedence over other defaults
  if (!hasSegmentedRewriteSort && rewriteSort) {
    /* If no default sort option exists and there is a rewrite we need to prepend
       an item to the sort options with the rewrite sort value. Otherwise a default option already
       exists and we can overwrite its values
      */

    if (sortOptions[0].defaultMessage === 'Default') {
      // This was the pre-existing behavior before the migration to TS, in order to not break anything we're just going to ignore this type warning
      // instead of modifying anything.
      // @ts-ignore
      sortOptions[0].value = sortOptions[0].sort = rewriteSort;
    } else {
      sortOptions = addNewDefaultSortOption(
        {
          value: rewriteSort,
          sort: rewriteSort,
          defaultMessage: 'Default',
          isDefault: true,
        },
        sortOptions
      );
    }

    initialSortOption = sortOptions[0];
  }

  if (hasSegmentedRewriteSort || rewriteSortOptions) {
    const { sortOptions: segmentedSortOptions, sortValue: segmentedSortValue } =
      getSegmentedSortOptions({
        potentialSegments,
        sortOptions: rewriteSortOptions,
        initialSortOptions: sortOptions,
        initialSortValue: rewriteSort,
      });

    sortOptions = segmentedSortOptions;
    initialSortOption = sortOptions.find(({ sort, isDefault }) => {
      if (isDefault) {
        return true;
      }

      if (typeof sort === 'string' && typeof segmentedSortValue === 'string') {
        return sort === segmentedSortValue;
      }

      return false;
    });
    if (!initialSortOption) {
      initialSortOption = sortOptions[0];
    }
  }

  return {
    initialSortOption,
    sortOptions,
  };
};

export function createProductsReducer(currentKey) {
  return function productsReducer(state, action) {
    switch (action.type) {
      case 'LOAD_PRODUCTS': {
        if (action.payloadKey !== currentKey) {
          return state;
        }
        // There may be multiple pages of responses in `payload`.
        return action.payload.reduce(
          (newState, payload) => {
            newState.aggregations = payload.aggregations;
            newState.totalCount = payload.total;
            newState.pages[payload.page] = payload.products;
            return newState;
          },
          {
            ...state,
            payloadKey: action.payloadKey,
            pages: {
              ...(state.payloadKey === currentKey ? state.pages : null),
            },
          }
        );
      }
      default:
        return state;
    }
  };
}
