import type { Component } from '@builder.io/sdk';

export const BuilderTextConfig: Component = {
  name: '_BrandedText_',
  friendlyName: 'SXF Text',
  inputs: [
    {
      name: 'text',
      friendlyName: 'Text',
      type: 'longText',
      localized: true,
      required: true,
      bubble: true,
      defaultValue: 'Enter some text...',
    },
    {
      name: 'variant',
      friendlyName: 'Text Variant',
      type: 'text',
      enum: [
        { label: 'Heading', value: 'heading' },
        { label: 'Subheading', value: 'subHeading' },
        { label: 'Paragraph', value: 'paragraph' },
      ],
      defaultValue: 'paragraph',
      required: true,
    },
    {
      name: 'headingLevel',
      friendlyName: 'Heading Level',
      type: 'text',
      enum: [
        { label: 'Heading 1', value: 'h1' },
        { label: 'Heading 2', value: 'h2' },
        { label: 'Heading 3', value: 'h3' },
      ],
      showIf: options => options.get('variant') === 'heading',
      defaultValue: 'h2',
    },
    {
      name: 'size',
      friendlyName: 'Size Level',
      type: 'text',
      enum: [
        { label: 'Level 1 (largest)', value: '1' },
        { label: 'Level 2', value: '2' },
        { label: 'Level 3', value: '3' },
        { label: 'Level 4', value: '4' },
        { label: 'Level 5 (smallest)', value: '5' },
      ],
      showIf: options => options.get('variant') !== 'heading',
      defaultValue: '2',
    },
    {
      name: 'isUpperCase',
      friendlyName: 'Uppercase',
      type: 'boolean',
      showIf: options =>
        ['subHeading', 'paragraph'].includes(options.get('variant')),
    },
    {
      name: 'elementTypeOverride',
      friendlyName: 'Element Type Override',
      helperText: 'This will override the default element type for the text',
      type: 'text',
      enum: [
        { label: 'Default', value: '' },
        { label: 'Paragraph', value: 'p' },
        { label: 'Span', value: 'span' },
        { label: 'Heading 1', value: 'h1' },
        { label: 'Heading 2', value: 'h2' },
        { label: 'Heading 3', value: 'h3' },
        { label: 'Heading 4', value: 'h4' },
        { label: 'Heading 5', value: 'h5' },
        { label: 'Heading 6', value: 'h6' },
      ],
      advanced: true,
    },
  ],
  defaultStyles: {
    marginTop: '0px',
  },
};
