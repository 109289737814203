import { useMemo } from 'react';

import { Builder } from '@builder.io/react';
import { useSelector } from 'react-redux';

import { useMembership } from '@techstyle/react-accounts';
import { useIntl } from '@techstyle/react-intl';
import { useMarketingDisposition } from '@techstyle/react-marketing';
import { useDomain } from '@techstyle/redux-core';

import {
  localeFromTld,
  mapBuilderPageOptions,
} from './getBuilderPageTargeting';
import { getBuilderMembershipStatus } from './selectors';

export const useBuilderTargetingOptions = () => {
  const { customerId } = useMembership();
  const { membershipStatus, networkStatus } = useSelector(
    getBuilderMembershipStatus
  );

  const { locale } = useIntl();
  const { tld } = useDomain();

  const isPreview = Builder.isPreviewing;
  const disposition = useMarketingDisposition();
  const experienceId = disposition?.experience;
  const localeTld = localeFromTld(tld, locale, isPreview);

  const pageOptions = useMemo(
    () => ({
      experienceId,
      isLoggedIn: !!customerId,
      isPreview,
      locale: localeTld,
      membershipStatus,
      tld,
    }),
    [locale, tld, membershipStatus, customerId, isPreview, experienceId]
  );

  // when trying to fetch memebership status the first time is outdated
  // and causes to load a wrong status, we need to wait until is completed loaded
  const isLoadingCustomer =
    networkStatus.isLoading || !networkStatus.isUpToDate;

  return useMemo(
    () => ({ isLoadingCustomer, ...mapBuilderPageOptions(pageOptions) }),
    [isLoadingCustomer, pageOptions]
  );
};
