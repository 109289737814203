import { loadRewrites, getRouteInfo } from '@techstyle/next-routes';

import { getBuilderCollectionContent } from './getBuilderCollectionContent';

const QUIZ_COMPLETE_URL_REWRITE = '/quiz-complete';

export const getGridNodeArrayFromCollectionsPageBuilderData = async ({
  ctx,
  name,
  builderData,
}) => {
  if (!builderData) {
    return [];
  }

  const pathname = ctx.asPath?.split('?') ? ctx.asPath?.split('?')[0] : null;
  const routeInfo = {
    canonicalPath: pathname,
  };

  let quizMap;

  if (name === '_RecommendedProducts_') {
    await ctx.store.dispatch(
      loadRewrites([QUIZ_COMPLETE_URL_REWRITE], { force: true })
    );
    const routeInfo = getRouteInfo(
      ctx.store.getState(),
      QUIZ_COMPLETE_URL_REWRITE
    );

    quizMap = routeInfo?.routeParamsJson?.quizMap;
  }

  const productGridItemParams = {
    options: builderData,
    dispatch: ctx.store.dispatch,
    routeInfo,
    store: ctx.store,
    ctx,
    isServer: true,
    quizMap,
  };

  const collectionContent = await getBuilderCollectionContent(
    productGridItemParams
  );
  return collectionContent;
};
