import type { Component } from '@builder.io/sdk';

export const BuilderQuizImageSelectorConfig: Component = {
  name: '_QuizImageSelector_',
  friendlyName: 'ImageSelector',
  defaultStyles: {
    minHeight: '0',
    flex: '1',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  inputs: [
    {
      name: 'questionName',
      type: 'string',
      defaultValue: 'productTypes',
      required: true,
    },
    {
      name: 'autoAdvance',
      type: 'boolean',
      defaultValue: false,
      helperText:
        'If autoAdvance is on, after selecting all items or the minimum required items, it advances to the next step.',
    },
    {
      name: 'minimumSelected',
      friendlyName: 'Minimum items selected',
      type: 'number',
      helperText:
        'Minimum number of items that should be selected to trigger autoAdvance.',
      required: true,
      defaultValue: 1,
      showIf: (options: any) => options.get('autoAdvance') === true,
      onChange: (options: any) => {
        if (options.get('minimumSelected') >= options.get('images').length) {
          options.set('minimumSelected', options.get('images').length);
        }
      },
    },
    {
      name: 'images',
      type: 'list',
      subFields: [
        {
          name: 'answerName',
          type: 'string',
          required: true,
        },
        {
          name: 'label',
          type: 'string',
          required: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fa96c1213fc0a4ee482acf359299eddc0',
        },
        {
          name: 'isNew',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
    {
      name: 'showSelectAll',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'selectAllLabel',
      type: 'string',
      defaultValue: 'I want everything',
    },
    {
      name: 'minimumItems',
      type: 'number',
      helperText:
        'Minimum number of items that should be selected for the "Next" button to be shown.',
      defaultValue: 1,
    },
    {
      name: 'nextButtonLabel',
      type: 'string',
      defaultValue: 'Next',
    },
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'secondary', 'alternate', 'white'],
      defaultValue: 'primary',
    },
    {
      name: 'shouldAnimate',
      type: 'boolean',
      helperText: 'Enable or disable the "Next" button animation.',
      defaultValue: true,
    },
  ],
};
